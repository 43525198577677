<template>
  <div class="LeetcodeClassification">
    <img
      class="banner"
      src="@/assets/img/LeetCodeClassification/bannerNew.png"
      alt=""
    />
    <div class="pageInfo">
      <div class="description">
        <template v-if="form.type == 1">
          <div class="title">《LeetCode 400题分类顺序表》</div>
          <div class="text">
            LeetCode 分类顺序表由3个表组成，分别是《LeetCode
            400题分类顺序表》《LeetCode 重点250题》《Data Science LeetCode
            精简版》
            <br />
            <br />
            <div>
              此表以先易后难 + 分类而成。Leetcode（https://leetcode.com/）
              并且类别分的并不是非常好，因为大量非最优解也涵盖在各种类别中。
              所以我们依据做题经验，最优解的类别，难度，重新划分。
              如果是第一次刷题的小伙伴，最好以本表的顺序为主，可以为大家节省时间，更有效率的做题，减少很多刷题的负担。
              <br />
              <br />
              注：本表非最终版本。《LeetCode
              千题视频讲解》课程中有最终Leetcode分类顺序表版本，更细致，每种题目对应方法一并写出，
              并且同类型做题方法归纳一起。
            </div>
          </div>
        </template>
        <template v-if="form.type == 5">
          <div class="title">《LeetCode 重点250题》</div>
          <div class="text">
            <div>
              这个重点题目是把Leetcode前400题进行精简，只保留高频250题，划分精简规则如下：
              <br />
              1. 删除不常考，面试低频出现题目
              <br />
              2. 删除重复代码题目（例：链表反转206题，代码在234题出现过）
              <br />
              3. 删除过于简单题目（例：100题：Same Tree）
              <br />
              4. 删除题意不同，代码基本相同题目（例：136 &amp; 389，保留一个）
              <br />
              所有题目尽量保证客观公正，只是按大概率删除不常考题目，很多题目面经出现过，
              但出现次数属于个位数或者只有一两家出现进行删除，这只是从概率上删除低频，简单题目，面试不一定会出现。
              旨在减轻大家的刷题负担，从400题减少到250题。
              <br />
              适用人群：有一定刷题基础，算法基础，二刷人群。
              <br />
              建议：400题全部刷完，再精刷这250题。
            </div>
          </div>
        </template>
        <template v-if="form.type == 6">
          <div class="title">《Data Science LeetCode 精简版》</div>
          <div class="text">
            <div>
              此表是针对 Data Science
              这个职位，对Leetcode前400题进行精简，划分精简规则如下：
              <br />
              1. 删除DS不常考，面试低频出现题目
              <br />
              2. 删除SDE考，过难题目
              <br />
              3. 删除高级数据结构算法，保留基础简单题目
              <br />
              4. 保留基础练手题目，提高代码能力
              <br />
              所有题目我们尽量保证客观公正，只是按大概率删除，目的是为了减轻DS的刷题负担。
              <br />
              适用人群：Data Science 职位相关人员
            </div>
          </div>
        </template>
      </div>
    </div>
    <div class="discussionArea">
      <div class="discussionList">
        <div class="title">讨论区</div>
        <div class="discussList">
          <div
            class="discuss"
            v-for="(discuss, index) in discussList"
            :key="index"
          >
            <img class="useravatar" :src="discuss.avatar" alt="" />
            <span class="username">{{
              discuss.username ? discuss.username : "匿名用户"
            }}</span>
            <span class="createTime">
              {{ discuss.createTime }}
            </span>
            <div class="discontent">
              {{ discuss.content && discuss.content.content }}
            </div>
          </div>
          <div
            v-if="discussList.length == 0"
            style="text-align: center; font-size: 18px; padding: 20px"
          >
            暂无讨论
          </div>
        </div>
        <div style="text-align: center">
          <router-link to="/leetcodeClassification/discussion">
            <div class="more">查看更多</div>
          </router-link>
        </div>
      </div>
    </div>
    <div class="searchFilter">
      <el-select
        class="selectParentType"
        v-model="form.type"
        @change="getChildSequenceTags"
      >
        <el-option
          v-for="(type, index) in lcTypes"
          :key="index"
          :label="type.name"
          :value="type.id"
        ></el-option>
      </el-select>

      <el-input
        class="searchLcKey"
        v-model="searchLcKey"
        @input="searchSequenceData"
        placeholder="请搜索"
      ></el-input>

      <div class="tags">
        <div
          :class="['filterTag', activeTag == 'all' ? 'active' : '']"
          @click="getAllList"
        >
          全部
        </div>
        <div
          :class="['filterTag', activeTag == tag.id ? 'active' : '']"
          v-for="(tag, index) in lcTags"
          :key="index"
          @click="changeActiveTag(tag)"
        >
          {{ tag.name }}
        </div>
      </div>
    </div>

    <div class="tableDatas">
      <div
        class="leetcodes"
        v-for="(code, index) in tableData"
        :key="index"
        @click="goDetail(code)"
      >
        <el-row>
          <el-col :span="3">
            <div class="questionNum">{{ code.questionNum }}.</div>
          </el-col>
          <el-col :span="21">
            <div class="questionTitle">
              {{ code.name }}
            </div>
            <div class="scriptions">
              <div class="types">
                {{ code.type }}
              </div>
              <div class="difficulty">
                {{ code.difficulty && code.difficulty.name }}
              </div>
              <div class="rating">{{ code.passingRate }}%</div>
            </div>
          </el-col>
        </el-row>
      </div>
      <div class="nodatas" v-if="total == 0">暂无数据</div>
    </div>
    <el-pagination
      style="padding: 20px; text-align: center"
      :current-page.sync="currentPage"
      :total="total"
      layout="prev, pager, next"
      :page-size="20"
      @current-change="
        (page) =>
          getSequenceData(activeTag == 'all' ? form.type : activeTag, page)
      "
    >
    </el-pagination>
  </div>
</template>
<script>
import {
  getSequenceTypes,
  getSequenceData,
  getDiscussion,
  browseLeetcode,
} from "@/service/leetcodeClassification";
export default {
  name: "Index",
  data() {
    return {
      lcTypes: [],
      lcTags: [],
      form: {
        type: "",
      },
      language: "en",
      activeTag: "all",
      currentPage: 1,
      total: 0,
      tableData: [],
      searchLcKey: "",
      discussList: [],
    };
  },
  mounted() {
    getSequenceTypes(0).then((res) => {
      if (res.success) {
        this.lcTypes = res.result;
        this.form.type = res.result[0].id;
        this.getChildSequenceTags(res.result[0].id);
      }
    });
    this.getDiscussion();
  },
  methods: {
    getChildSequenceTags(id) {
      getSequenceTypes(id).then((res) => {
        if (res.success) {
          this.lcTags = res.result;
          this.getAllList();
        }
      });
    },
    getAllList() {
      this.searchLcKey = "";
      this.activeTag = "all";
      this.currentPage = 1;
      this.getSequenceData(this.form.type);
    },
    changeActiveTag(tag) {
      this.activeTag = tag.id;
      this.searchLcKey = "";
      this.currentPage = 1;
      this.getSequenceData(tag.id);
    },
    getSequenceData(id, page) {
      if (!page) {
        this.currentPage = 1;
      } else {
        this.currentPage = page;
      }
      getSequenceData(id, {
        name: this.searchLcKey,
        current: this.currentPage,
        size: 20,
      }).then((res) => {
        if (res.success) {
          this.tableData = res.result;
          this.total = res.result.total;
        }
      });
    },
    jumpUrl(row) {
      this.$router.push(`/watchVideo?id=${row.resourceId}`);
    },
    searchSequenceData() {
      this.getSequenceData(
        this.activeTag == "all" ? this.form.type : this.activeTag,
        1
      );
    },
    getDiscussion() {
      getDiscussion().then((res) => {
        if (res.success) {
          this.discussList = res.result.records;
        }
      });
    },
    goDetail(row) {
      browseLeetcode(row.id).then((res) => {
        if (res.success) {
          this.$router.push(
            `/leetcodeClassification/codeDetail?id=${row.id}&language=${this.language}`
          );
        }
      });
    },
  },
};
</script>
<style scoped lang="scss">
.LeetcodeClassification {
  background: #f8f8f8;
  .banner {
    width: 100%;
    vertical-align: top;
  }
  .pageInfo {
    padding: 30px 15px;
    background: #fff;
    .title {
      font-size: 14px;
      font-family: Source Han Sans CN-Medium, Source Han Sans CN;
      font-weight: 500;
      color: rgba(0, 0, 0, 0.8);
      line-height: 18px;
      margin-bottom: 20px;
    }
    .description {
      font-size: 12px;
      font-family: Source Han Sans CN-Medium, Source Han Sans CN;
      font-weight: 500;
      color: rgba(0, 0, 0, 0.5);
      line-height: 18px;
    }
  }
  .discussionArea {
    width: calc(100% - 50px);
    margin: 20px 10px;
    background: #fff;
    border-radius: 4px;
    padding: 20px 15px;
  }
}
.discussionList {
  background: #fff;
  margin-bottom: 8px;
  border-radius: 12px;
  .title {
    margin-top: 6px;
    position: relative;
    padding-left: 16px;
    line-height: 24px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    font-size: 14px;
    font-family: SourceHanSansCN-Medium, SourceHanSansCN;
    font-weight: 500;
    padding-bottom: 12px;
    color: #34495e;
    &::before {
      position: absolute;
      content: "";
      left: 0;
      top: 0;
      width: 4px;
      height: 24px;
      background: #0084ff;
      border-radius: 2px;
    }
  }
  .more {
    display: inline-block;
    margin: 0 auto;
    text-align: center;
    font-size: 12px;
    font-family: SourceHanSansCN-Medium, SourceHanSansCN;
    font-weight: 500;
    color: #0075f6;
    line-height: 12px;
    background: rgba(10, 122, 255, 0.20000000298023224);
    border: 1px solid rgba(10, 122, 255, 1);
    padding: 6px 30px;
    border-radius: 2px;
    margin-top: 20px;
  }
  .discuss {
    padding: 6px 0;
    border-bottom: 1px solid rgba(235, 236, 241, 0.91);
    .useravatar {
      width: 20px;
      height: 20px;
      border-radius: 50%;
      vertical-align: top;
      margin-right: 4px;
    }
    .username {
      font-size: 12px;
      font-family: SourceHanSansCN-Medium, SourceHanSansCN;
      font-weight: 500;
      color: #34495e;
      line-height: 18px;
      margin-right: 4px;
    }
    .createTime {
      font-size: 12px;
      font-family: SourceHanSansCN-Regular, SourceHanSansCN;
      font-weight: 400;
      color: rgba(52, 73, 94, 0.5);
      line-height: 18px;
    }
    .discontent {
      font-size: 12px;
      font-family: SourceHanSansCN-Regular, SourceHanSansCN;
      font-weight: 400;
      color: #828282;
      line-height: 18px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}

.filterTag {
  font-size: 14px;
  font-family: SourceHanSansCN-Medium, SourceHanSansCN;
  font-weight: 500;
  color: rgba(170, 170, 170, 0.8);
  line-height: 21px;
  padding: 0 10px;
  cursor: pointer;
  &:last-child {
    border: none;
  }
  &.active {
    color: #0075f6;
  }
}
.searchFilter {
  padding: 20px 15px;
  background: #fff;
  .selectParentType {
    width: 100%;
    margin-bottom: 16px;
  }
  .searchLcKey {
    width: 100%;
    margin-bottom: 16px;
  }
}
.tableDatas {
  width: calc(100% - 20px);
  margin: 0 10px;
  margin-top: 14px;
  .leetcodes {
    padding: 22px 15px;
    background: #fff;
    margin-bottom: 14px;
    .questionNum {
      font-size: 16px;
      font-family: Source Han Sans CN-Medium, Source Han Sans CN;
      font-weight: 500;
      color: #34495e;
      line-height: 16px;
    }
    .questionTitle {
      font-size: 12px;
      font-family: Source Han Sans CN-Medium, Source Han Sans CN;
      font-weight: 500;
      color: #8793aa;
      line-height: 16px;
    }
    .scriptions {
      margin-top: 14px;
      display: flex;
      justify-content: space-between;
      width: 80%;
      .types {
        background: rgba(10, 122, 255, 0.20000000298023224);
        font-size: 12px;
        font-family: Roboto-Medium, Roboto;
        font-weight: 500;
        color: #0a7aff;
        padding: 2px;
        border: 2px;
      }
      .difficulty {
        font-size: 12px;
        font-family: Roboto-Medium, Roboto;
        font-weight: 500;
        color: #ff3b30;
      }
      .rating {
        font-size: 12px;
        font-family: Roboto-Medium, Roboto;
        font-weight: 500;
        color: #34495e;
      }
    }
  }
}
.tags {
  display: flex;
  flex-wrap: wrap;
}
.nodatas {
  text-align: center;
}
</style>